import instance from './instancev2'

export const getUser = async ({ userEmail }) => {
	const response = await instance.get('/answer/users')

	if (!response.data) {
		return response
	}

	if (/\b(\w*@digitalk.com.br\w*)\b/.test(userEmail)) {
		return response
	}

	return {
		...response,
		data: response.data.filter(
			user =>
				!user.email.includes('@digitalk.com.br') &&
				!user.email.includes('@comtexto.ai')
		),
		digitalkUsers: response.data.filter(
			user =>
				user.email.includes('@digitalk.com.br') ||
				user.email.includes('@comtexto.ai')
		),
	}
}

export const getBlockedWord = () => instance.get('/answer/blokedword')

export const getFaq = () => instance.get('/answer/faqs')

export const getDepartment = () => instance.get('/answer/departments')

export const getByDepartment = id => instance.get(`/answer/department/${id}`)
