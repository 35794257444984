import axios from 'axios'
import dayjs from 'dayjs'
//import Bowser from "bowser"
import { decode } from 'jsonwebtoken'

import { env } from '../env.js'

const instance = axios.create({
	baseURL: `${env.REACT_APP_API_URL}/api/v1`,
	headers: {
		'Content-Type': 'application/json',
		/*"browser-ra": JSON.stringify(Bowser.getParser(window.navigator.userAgent)),*/
		'Access-Control-Max-Age': 600,
	},
})

instance.interceptors.request.use(
	async config => {
		const token = localStorage.getItem('token')
		if (token) {
			const decodedToken = decode(token)

			// Verifica se precisa de 2FA e não tem 2FA
			if (
				decodedToken.needTwoFactorAuthentication &&
				!decodedToken.twoFactorAuthenticated
			) {
				localStorage.removeItem('token')
				window.location.href = '/login'
				return Promise.reject(
					'Autenticação de dois fatores necessária. Redirecionando para o login.'
				)
			}

			config.headers.Authorization = `Bearer ${token}`
		}
		return config
	},
	error => Promise.reject(error)
)

instance.interceptors.response.use(
	response => {
		localStorage.setItem('last_request', dayjs())

		return response
	},
	error => Promise.reject(error)
)

export default instance
